// project import
import services from 'utils/mockAdapter';

// ==============================|| MENU ITEMS - DASHBOARD  ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Dashboard',
  type: 'group',
  icon: 'dashboardOutlined',
  children: [
    {
      id: 'dashboard',
      title: '종합정보',
      url: '/dashboard/default',
      type: 'item',
      icon: 'dashboardOutlined',
      breadcrumbs: false
    },
    // {
    //   id: 'diecasting-list',
    //   title: '모니터링-old',
    //   type: 'item',
    //   url: '/monitoring/diecasting_list',
    //   icon: 'desktopOutlined',
    //   breadcrumbs: false
    // },
    {
      id: 'custom_monitoring_main',
      title: '모니터링',
      type: 'item',
      url: '/monitoring/custom_monitoring_main',
      icon: 'desktopOutlined',
      breadcrumbs: false
    }
    // {
    //   id: 'components',
    //   title: 'components',
    //   type: 'item',
    //   url: '/components-overview/buttons',
    //   icon: 'goldOutlined',
    //   target: true,
    //   chip: {
    //     label: 'new',
    //     color: 'primary',
    //     size: 'small',
    //     variant: 'combined'
    //   }
    // }
  ]
};

// ==============================|| MOCK SERVICES ||============================== //

services.onGet('/api/dashboard').reply(200, { dashboard: dashboard });
