// assets
import {
  AppstoreAddOutlined,
  AreaChartOutlined,
  BuildOutlined,
  ThunderboltOutlined,
  FormOutlined,
  ExperimentOutlined,
  CarOutlined,
  RestOutlined,
  FireOutlined,
  RobotOutlined,
  MenuUnfoldOutlined,
  ShoppingCartOutlined,
  ReconciliationOutlined
} from '@ant-design/icons';

// icons
const icons = {
  AppstoreAddOutlined,
  AreaChartOutlined,
  BuildOutlined,
  ThunderboltOutlined,
  FormOutlined,
  ExperimentOutlined,
  CarOutlined,
  RestOutlined,
  FireOutlined,
  RobotOutlined,
  MenuUnfoldOutlined,
  ShoppingCartOutlined,
  ReconciliationOutlined
};
// ==============================|| MENU ITEMS - mes ||============================== //

const esg = {
  id: 'esg',
  title: 'ESG',
  icon: icons.AppstoreAddOutlined,
  type: 'group',
  children: [
    // {
    //   id: 'esg-productCarbon',
    //   title: '공정배출(S1)',
    //   type: 'item',
    //   url: '/esg/productCarbon',
    //   icon: icons.BuildOutlined,
    //   breadcrumbs: false
    // },
    {
      id: 'esg-power',
      title: '전력관리(S2)',
      type: 'item',
      url: '/esg/power',
      icon: icons.ThunderboltOutlined,
      breadcrumbs: false
    }
    // {
    //   id: 'esg-dashboard',
    //   title: '탄소배출',
    //   type: 'item',
    //   url: '/esg/dashboard',
    //   icon: icons.AreaChartOutlined,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'GHG-manageHistory',
    //   title: '탄소배출 관리',
    //   type: 'collapse',
    //   icon: icons.FormOutlined,
    //   children: [
    //     {
    //       id: 'GHG-purchaseList',
    //       title: '구매(S3)',
    //       type: 'item',
    //       url: '/purchases/list',
    //       icon: icons.ShoppingCartOutlined,
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'fixedFuelList',
    //       title: '고정연료(S1)',
    //       type: 'item',
    //       url: '/fixedFuel/list',
    //       icon: icons.ExperimentOutlined,
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'mobileFuelList',
    //       title: '이동연료(S1)',
    //       type: 'item',
    //       url: '/mobileFuel/list',
    //       icon: icons.CarOutlined,
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'wasteList',
    //       title: '폐기물(S1)',
    //       type: 'item',
    //       url: '/waste/list',
    //       icon: icons.RestOutlined,
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'steamList',
    //       title: '열/스팀(S2)',
    //       type: 'item',
    //       url: '/steam/list',
    //       icon: icons.FireOutlined,
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'processEmList',
    //       title: '공정배출(S1)',
    //       type: 'item',
    //       url: '/processEm/list',
    //       icon: icons.RobotOutlined,
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'GHG-vehicleDrivingLog',
    //       title: '차량일지(S3)',
    //       type: 'item',
    //       url: '/vehicleDrivingLog/default',
    //       icon: icons.MenuUnfoldOutlined,
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'emissionReductionEffort',
    //       title: '감축노력기준',
    //       type: 'item',
    //       url: '/emissionReductionEffort/list',
    //       icon: icons.ReconciliationOutlined,
    //       breadcrumbs: false
    //     }
    //   ]
    // }
  ]
};

export default esg;
