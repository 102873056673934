import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
// import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - eag
// const EagDashboard = Loadable(lazy(() => import('pages/esg/dashboard/default')));

// const ProductCarbon = Loadable(lazy(() => import('pages/esg/productCarbon/list')));
// const ProductCarbonDetails = Loadable(lazy(() => import('pages/esg/productCarbon/details')));

const Power = Loadable(lazy(() => import('pages/esg/power/default')));
const PowerInfo = Loadable(lazy(() => import('pages/esg/power/info')));

// const FixedFuelList = Loadable(lazy(() => import('pages/GHG/fixedFuel/list')));
// const FixedFuelCreate = Loadable(lazy(() => import('pages/GHG/fixedFuel/create')));
// const FixedFuelEdit = Loadable(lazy(() => import('pages/GHG/fixedFuel/edit')));
// const FixedFuelDetails = Loadable(lazy(() => import('pages/GHG/fixedFuel/details')));

// const MobileFuelList = Loadable(lazy(() => import('pages/GHG/mobileFuel/list')));
// const MobileFuelCreate = Loadable(lazy(() => import('pages/GHG/mobileFuel/create')));
// const MobileFuelEdit = Loadable(lazy(() => import('pages/GHG/mobileFuel/edit')));
// const MobileFuelDetails = Loadable(lazy(() => import('pages/GHG/mobileFuel/details')));

// const WasteList = Loadable(lazy(() => import('pages/GHG/waste/list')));
// const WasteCreate = Loadable(lazy(() => import('pages/GHG/waste/create')));
// const WasteEdit = Loadable(lazy(() => import('pages/GHG/waste/edit')));
// const WasteDetails = Loadable(lazy(() => import('pages/GHG/waste/details')));

// const SteamList = Loadable(lazy(() => import('pages/GHG/steam/list')));
// const SteamCreate = Loadable(lazy(() => import('pages/GHG/steam/create')));
// const SteamEdit = Loadable(lazy(() => import('pages/GHG/steam/edit')));
// const SteamDetails = Loadable(lazy(() => import('pages/GHG/steam/details')));

// const ProcessEmList = Loadable(lazy(() => import('pages/GHG/processEm/list')));
// const ProcessEmCreate = Loadable(lazy(() => import('pages/GHG/processEm/create')));
// const ProcessEmEdit = Loadable(lazy(() => import('pages/GHG/processEm/edit')));
// const ProcessEmDetails = Loadable(lazy(() => import('pages/GHG/processEm/details')));

// const VehicleDrivingLog = Loadable(lazy(() => import('pages/GHG/vehicleDrivingLog/default')));

// const PurchasesList = Loadable(lazy(() => import('pages/GHG/purchase/list')));
// const PurchasesCreate = Loadable(lazy(() => import('pages/GHG/purchase/create')));
// const PurchasesEdit = Loadable(lazy(() => import('pages/GHG/purchase/edit')));
// const PurchasesDetails = Loadable(lazy(() => import('pages/GHG/purchase/details')));

// const EmissionReductionEffortList = Loadable(lazy(() => import('pages/GHG/emissionReductionEffort/list')));
// const EmissionReductionEffortCreate = Loadable(lazy(() => import('pages/GHG/emissionReductionEffort/create')));
// const EmissionReductionEffortEdit = Loadable(lazy(() => import('pages/GHG/emissionReductionEffort/edit')));

// ==============================|| eag ROUTING ||============================== //

const EsgRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'esg',
          children: [
            // {
            //   path: 'dashboard',
            //   element: <EagDashboard />
            // },
            // {
            //   path: 'productCarbon',
            //   element: <ProductCarbon />
            // },
            // {
            //   path: 'productCarbon_details/:id',
            //   element: <ProductCarbonDetails />
            // },
            {
              path: 'power',
              element: <Power />
            },
            {
              path: 'powerInfo',
              element: <PowerInfo />
            }
          ]
        }
        // {
        //   path: 'fixedFuel',
        //   children: [
        //     {
        //       path: 'list',
        //       element: <FixedFuelList />
        //     },
        //     {
        //       path: 'create',
        //       element: <FixedFuelCreate />
        //     },
        //     {
        //       path: 'edit/:id',
        //       element: <FixedFuelEdit />
        //     },
        //     {
        //       path: 'details/:id',
        //       element: <FixedFuelDetails />
        //     }
        //   ]
        // },
        // {
        //   path: 'mobileFuel',
        //   children: [
        //     {
        //       path: 'list',
        //       element: <MobileFuelList />
        //     },
        //     {
        //       path: 'create',
        //       element: <MobileFuelCreate />
        //     },
        //     {
        //       path: 'edit/:id',
        //       element: <MobileFuelEdit />
        //     },
        //     {
        //       path: 'details/:id',
        //       element: <MobileFuelDetails />
        //     }
        //   ]
        // },
        // {
        //   path: 'waste',
        //   children: [
        //     {
        //       path: 'list',
        //       element: <WasteList />
        //     },
        //     {
        //       path: 'create',
        //       element: <WasteCreate />
        //     },
        //     {
        //       path: 'edit/:id',
        //       element: <WasteEdit />
        //     },
        //     {
        //       path: 'details/:id',
        //       element: <WasteDetails />
        //     }
        //   ]
        // },
        // {
        //   path: 'steam',
        //   children: [
        //     {
        //       path: 'list',
        //       element: <SteamList />
        //     },
        //     {
        //       path: 'create',
        //       element: <SteamCreate />
        //     },
        //     {
        //       path: 'edit/:id',
        //       element: <SteamEdit />
        //     },
        //     {
        //       path: 'details/:id',
        //       element: <SteamDetails />
        //     }
        //   ]
        // },
        // {
        //   path: 'processEm',
        //   children: [
        //     {
        //       path: 'list',
        //       element: <ProcessEmList />
        //     },
        //     {
        //       path: 'create',
        //       element: <ProcessEmCreate />
        //     },
        //     {
        //       path: 'edit/:id',
        //       element: <ProcessEmEdit />
        //     },
        //     {
        //       path: 'details/:id',
        //       element: <ProcessEmDetails />
        //     }
        //   ]
        // },
        // {
        //   path: 'vehicleDrivingLog',
        //   children: [
        //     {
        //       path: 'default',
        //       element: <VehicleDrivingLog />
        //     }
        //   ]
        // },
        // {
        //   path: 'purchases',
        //   children: [
        //     {
        //       path: 'list',
        //       element: <PurchasesList />
        //     },
        //     {
        //       path: 'create',
        //       element: <PurchasesCreate />
        //     },
        //     {
        //       path: 'edit/:id',
        //       element: <PurchasesEdit />
        //     },
        //     {
        //       path: 'details/:id',
        //       element: <PurchasesDetails />
        //     }
        //   ]
        // },
        // {
        //   path: 'emissionReductionEffort',
        //   children: [
        //     {
        //       path: 'list',
        //       element: <EmissionReductionEffortList />
        //     },
        //     {
        //       path: 'create',
        //       element: <EmissionReductionEffortCreate />
        //     },
        //     {
        //       path: 'edit/:id',
        //       element: <EmissionReductionEffortEdit />
        //     }
        //   ]
        // }
      ]
    }
  ]
};

export default EsgRoutes;
