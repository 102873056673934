import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  lists: [],
  error: null
};

const slice = createSlice({
  name: 'userSetup',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    createUserSetup(state, action) {
      state.lists.push(action.payload);
    }
  }
});

export const { createUserSetup } = slice.actions;
export default slice.reducer;

const config = {
  headers: {
    'content-type': 'multipart/form-data',
    'x-access-token': localStorage.getItem('serviceToken')
  }
};

export function getUserSetupInsert(newSetup) {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append('user_id', newSetup.user_id);
      formData.append('diecasting_column', newSetup.diecasting_column);

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/userSetup/userSetupUpload`, formData, config);

      if (response.status === 200) {
        dispatch(createUserSetup(response.data));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || 'Failed to insert user setup');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}
