import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';

const initialState = {
  lists: [],
  error: null
};

const slice = createSlice({
  name: 'powerWeekDashboard',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getPowerWeekChart(state, action) {
      state.lists = action.payload;
    }
  }
});

export const { getPowerWeekChart } = slice.actions;
export default slice.reducer;

export function getPowerApiWeekChart() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/dashboard/powerApiWeekChart' });
      console.log('response', response.data);
      // dispatch(getPowerWeekChart(response.data));
      dispatch(slice.actions.getPowerWeekChart(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}
