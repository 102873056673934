import api from './ApiAdaptor';

class ApiService {
  async post(objectData) {
    try {
      const response = await api.post(objectData.path, objectData);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async put(objectData) {
    try {
      const response = await api.put(objectData.path, objectData);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async get(objectData) {
    const params = { params: objectData };
    try {
      const response = await api.get(objectData.path, params);
      // console.log('response:', response);
      return response;
    } catch (error) {
      return error.response;
    }
  }
}

export default new ApiService();

// ApiService.js
// import api from './ApiAdaptor';

// class ApiService {
//   async post(objectData) {
//     try {
//       const response = await api.post(objectData.path, objectData);
//       return response;
//     } catch (error) {
//       console.error('POST request error:', error);
//       return this.handleError(error);
//     }
//   }

//   async put(objectData) {
//     try {
//       const response = await api.put(objectData.path, objectData);
//       return response;
//     } catch (error) {
//       console.error('PUT request error:', error);
//       return this.handleError(error);
//     }
//   }

//   async get(objectData) {
//     const params = { params: objectData };
//     try {
//       const response = await api.get(objectData.path, params);
//       return response;
//     } catch (error) {
//       console.error('GET request error:', error);
//       return this.handleError(error);
//     }
//   }

//   handleError(error) {
//     if (error.response) {
//       // 서버가 2xx 범위를 벗어난 상태 코드로 응답한 경우
//       console.error('Error response:', error.response.data);
//       console.error('Error status:', error.response.status);
//       console.error('Error headers:', error.response.headers);
//       return error.response;
//     } else if (error.request) {
//       // 요청이 이루어졌으나 응답을 받지 못한 경우
//       console.error('Error request:', error.request);
//       return { data: null, status: 0, statusText: 'No response received' };
//     } else {
//       // 요청을 설정하는 중에 오류가 발생한 경우
//       console.error('Error message:', error.message);
//       return { data: null, status: 0, statusText: error.message };
//     }
//   }
// }
// export default new ApiService();
