import ApiService from 'services/ApiService';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lists: [],
  error: null
};

const slice = createSlice({
  name: 'diecastingAlm',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
    },
    getDiecastingAlmTotalQty(state, action) {
      state.lists = action.payload;
    }
  }
});

export const { getLists, getDiecastingAlmTotalQty } = slice.actions;
export default slice.reducer;

export function getDiecastingAlmList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/diecastingAlm/list' });
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}

export function getDiecastingAlmDayQty() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/dashboard/diecastingAlmDayQty' });
      dispatch(slice.actions.getDiecastingAlmTotalQty(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}
