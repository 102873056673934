import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const initialState = {
  lists: [],
  error: null,
  alertPopup: false
};

const slice = createSlice({
  name: 'emissionReductionEffort',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
    },
    createEmissionReductionEffort(state, action) {
      state.lists.push(action.payload);
    },
    updateEmissionReductionEffort(state, action) {
      const updatedEffort = action.payload;
      const index = state.lists.findIndex((item) => item.id === updatedEffort.id);
      if (index !== -1) {
        state.lists[index] = updatedEffort;
      }
    },
    deleteEmissionReductionEffort(state, action) {
      state.lists = state.lists.filter((item) => item.id !== action.payload);
    },
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload;
    }
  }
});

export const { getLists, createEmissionReductionEffort, updateEmissionReductionEffort, deleteEmissionReductionEffort, alertPopupToggle } =
  slice.actions;

export default slice.reducer;

const config = {
  headers: {
    'content-type': 'multipart/form-data',
    'x-access-token': localStorage.getItem('serviceToken')
  }
};

export function getEmissionReductionEffortList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/emissionReductionEffort/list' });
      dispatch(getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}

export function getEmissionReductionEffortInsert(newEffort) {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append('name', newEffort.name);
      formData.append('description', newEffort.description);

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/emissionReductionEffort/emissionReductionEffortUpload`,
        formData,
        config
      );

      if (response.status === 200) {
        dispatch(createEmissionReductionEffort(response.data));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || 'Failed to insert emission reduction effort');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}

export function getEmissionReductionEffortUpdate(updatedEffort, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const formData = new FormData();
        formData.append('id', updatedEffort.id);
        formData.append('name', updatedEffort.name);
        formData.append('description', updatedEffort.description);

        response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/emissionReductionEffort/emissionReductionEffortUpdate`,
          formData,
          config
        );
      } else {
        response = await ApiService.post({
          path: '/emissionReductionEffort/update',
          data: updatedEffort
        });
      }

      if (response.status === 200) {
        dispatch(updateEmissionReductionEffort(response.data));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || 'Failed to update emission reduction effort');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}

export function getEmissionReductionEffortDelete(effortId) {
  return async (dispatch) => {
    try {
      const response = await ApiService.post({
        path: '/emissionReductionEffort/delete',
        data: { id: effortId }
      });
      if (response.status === 200) {
        dispatch(deleteEmissionReductionEffort(effortId));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || 'Failed to delete emission reduction effort');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}
