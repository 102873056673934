import ApiService from 'services/ApiService';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lists: [],
  error: null
};

const slice = createSlice({
  name: 'powerApiData',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getPowerTodayLists(state, action) {
      state.lists = action.payload;
    }
  }
});

export const { getPowerTodayLists } = slice.actions;
export default slice.reducer;

export function getPowerTodayTotal() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/dashboard/powerTodayTotal' });
      dispatch(slice.actions.getPowerTodayLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}
