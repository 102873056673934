import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  },
  timeout: 30 * 60 * 1000 // 30분으로 타임아웃 설정
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  uploadedFiles: [],
  isUploading: false,
  error: null
};

const slice = createSlice({
  name: 'multiFilesUpload',
  initialState,
  reducers: {
    uploadStart(state) {
      state.isUploading = true;
      state.error = null;
    },
    uploadSuccess(state, action) {
      state.isUploading = false;
      state.uploadedFiles.push(action.payload);
    },
    uploadFailure(state, action) {
      state.isUploading = false;
      state.error = action.payload;
    },
    clearUploadedFiles(state) {
      state.uploadedFiles = [];
    }
  }
});

export const { uploadStart, uploadSuccess, uploadFailure, clearUploadedFiles } = slice.actions;

export default slice.reducer;

export function categoryMultiFilesUpload(files, folder) {
  console.log('categoryMultiFilesUpload files:', files, 'folder:', folder);
  return async (dispatch) => {
    dispatch(uploadStart());
    try {
      const data = new FormData();
      Object.entries(files).forEach(([category, categoryFiles]) => {
        categoryFiles.forEach((file) => {
          data.append(category, file);
        });
      });
      data.append('folder', folder);

      console.log('categoryMultiFilesUpload data:', data);

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/categoryMultiFilesUpload?folder=${folder}`, data, config);

      if (response.status === 200) {
        dispatch(uploadSuccess(response.data));
        console.log('categoryMultiFilesUpload response:', response.data);
        return { success: true, data: response.data };
      } else {
        dispatch(uploadFailure(response.data.message));
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Upload error:', error);
      dispatch(uploadFailure(error.toString()));
      return { success: false, error };
    }
  };
}

export function clearFiles() {
  return (dispatch) => {
    dispatch(clearUploadedFiles());
  };
}
