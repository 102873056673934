import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null
};

const slice = createSlice({
  name: 'materialHistory',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    getSingleList(state, action) {
      state.list = action.payload;
    },
    createMaterialHistory(state, action) {
      state.lists.items = [...state.lists.items, action.payload];
      state.lists.totalItems += 1;
    },
    updateMaterialHistory(state, action) {
      const updatedMaterialHistory = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedMaterialHistory.id ? updatedMaterialHistory : item));
    },
    deleteMaterialHistory(state, action) {
      const materialHistoryId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== materialHistoryId);
      state.lists.totalItems -= 1;
    }
  }
});

export const { getLists, getSingleList, createMaterialHistory, updateMaterialHistory, deleteMaterialHistory } = slice.actions;

export default slice.reducer;

export function getMaterialHistoryList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/materialHistory/list',
        params: { page, pageSize }
      };
      const response = await ApiService.get(objData);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getMaterialHistoryInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, NewLists[key]);
        }
      });
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/materialHistory/materialHistoryUpload`, data, config);
      if (response.status === 200) {
        dispatch(createMaterialHistory(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getMaterialHistorySingleList(materialHistoryId) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/api/materialHistory/list/single', { id: materialHistoryId });
      dispatch(getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getMaterialHistoryUpdate(NewLists, id, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const data = new FormData();
        Object.keys(NewLists).forEach((key) => {
          if (key === 'files' && NewLists[key] && NewLists[key][0]) {
            data.append('file', NewLists[key][0]);
          } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
            data.append(key, NewLists[key]);
          }
        });
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/materialHistory/materialHistoryUpdate`, data, config);
      } else {
        const objData = {
          path: '/materialHistory/update',
          data: NewLists
        };
        response = await ApiService.post(objData);
      }

      if (response.status === 200) {
        dispatch(updateMaterialHistory(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getMaterialHistoryDelete(materialHistoryId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/materialHistory/delete',
        data: { id: materialHistoryId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteMaterialHistory(materialHistoryId));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
