import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null
};

const slice = createSlice({
  name: 'factory',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    getNoPageLists(state, action) {
      state.lists = action.payload;
    },
    getSingleList(state, action) {
      state.list = action.payload;
    },
    createFactory(state, action) {
      state.lists.items = [...state.lists.items, action.payload];
      state.lists.totalItems += 1;
    },
    updateFactory(state, action) {
      const updatedFactory = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedFactory.id ? updatedFactory : item));
    },
    deleteFactory(state, action) {
      const factoryId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== factoryId);
      state.lists.totalItems -= 1;
    }
  }
});

export const { getLists, getNoPageLists, getSingleList, createFactory, updateFactory, deleteFactory } = slice.actions;

export default slice.reducer;

export function getFactoryList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/factory/list',
        params: { page, pageSize }
      };
      const response = await ApiService.get(objData);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getFactoryNoPageList() {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/factory/noPageList'
      };
      const response = await ApiService.get(objData);
      console.log('get FactoryNoPage List', response.data);
      dispatch(getNoPageLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}

export function getFactoryInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, NewLists[key]);
        }
      });
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/factory/factoryUpload`, data, config);
      if (response.status === 200) {
        dispatch(createFactory(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getFactorySingleList(factoryId) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/api/factory/list/single', factoryId);
      dispatch(getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getFactoryUpdate(NewLists, id, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const data = new FormData();
        Object.keys(NewLists).forEach((key) => {
          if (key === 'files' && NewLists[key][0]) {
            data.append('file', NewLists[key][0]);
          } else {
            data.append(key, NewLists[key]);
          }
        });
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/factory/factoryUpdate`, data, config);
      } else {
        const objData = {
          path: '/factory/update',
          data: NewLists
        };
        response = await ApiService.post(objData);
      }

      if (response.status === 200) {
        dispatch(updateFactory(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getFactoryDelete(factoryId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/factory/delete',
        data: { id: factoryId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteFactory(factoryId));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}
