import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null,
  alertPopup: false
};

const slice = createSlice({
  name: 'productionPlan',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    getEndLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    getSingleList(state, action) {
      state.list = action.payload;
    },
    createProductionPlan(state, action) {
      state.lists.items = [...state.lists.items, action.payload];
      state.lists.totalItems += 1;
    },
    updateProductionPlan(state, action) {
      const updatedProductionPlan = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedProductionPlan.id ? updatedProductionPlan : item));
    },
    deleteProductionPlan(state, action) {
      const productionPlanId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== productionPlanId);
      state.lists.totalItems -= 1;
    },
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    },
    removeRestoredPlan(state, action) {
      const restoredPlanId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== restoredPlanId);
      state.lists.totalItems -= 1;
    }
  }
});

export const {
  removeRestoredPlan,
  getLists,
  getEndLists,
  getSingleList,
  createProductionPlan,
  updateProductionPlan,
  deleteProductionPlan,
  alertPopupToggle
} = slice.actions;

export default slice.reducer;

export function getProductionPlanList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/productionPlan/list',
        params: { page, pageSize }
      };
      const response = await ApiService.get(objData);
      // console.log('response', response);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getProductionPlanEndList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/productionPlan/endList',
        params: { page, pageSize }
      };
      const response = await ApiService.get(objData);
      // console.log('response', response);
      dispatch(
        getEndLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getProductionPlanInsert(NewLists) {
  console.log('NewLists', NewLists);
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (key === 'bomItems' && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (key === 'lineBomItems' && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, typeof NewLists[key] === 'object' ? JSON.stringify(NewLists[key]) : NewLists[key]);
        }
      });

      console.log('data', data);

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/productionPlan/productionPlanUpload`, data, config);

      if (response.status === 200) {
        console.log('response', response);
        dispatch(slice.actions.createProductionPlan(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getProductionPlanSingleList(productionPlanId) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/api/productionPlan/list/single', { id: productionPlanId });
      dispatch(getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getProductionPlanUpdate(NewLists, id, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const data = new FormData();
        Object.keys(NewLists).forEach((key) => {
          if (key === 'files' && NewLists[key] && NewLists[key][0]) {
            data.append('file', NewLists[key][0]);
          } else if (key === 'bomItems' && Array.isArray(NewLists[key])) {
            data.append(key, JSON.stringify(NewLists[key]));
          } else if (key === 'lineBomItems' && Array.isArray(NewLists[key])) {
            data.append(key, JSON.stringify(NewLists[key]));
          } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
            data.append(key, typeof NewLists[key] === 'object' ? JSON.stringify(NewLists[key]) : NewLists[key]);
          }
        });
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/productionPlan/productionPlanUpdate`, data, config);
      } else {
        const objData = {
          path: '/productionPlan/update',
          data: NewLists
        };
        response = await ApiService.post(objData);
        console.log('response', response);
      }

      if (response.status === 200) {
        console.log('response', response);
        dispatch(updateProductionPlan(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getProductionPlanEndUpdate(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      data.append('id', NewLists.id);
      data.append('deadline_date', NewLists.deadline_date);
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/productionPlan/productionPlanEndUpdate`, data, config);

      if (response.status === 200) {
        dispatch(updateProductionPlan(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getProductionPlanRestore(productionPlanId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/productionPlan/productionPlanRestore',
        data: { id: productionPlanId }
      };

      const response = await ApiService.post(objData);

      if (response.status === 200) {
        dispatch(removeRestoredPlan(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getProductionPlanDelete(productionPlanId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/productionPlan/delete',
        data: { id: productionPlanId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteProductionPlan(productionPlanId));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}

export function getProductionPlanEnd(productionPlanId, selectedDeadlineDate) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/productionPlan/end',
        data: { id: productionPlanId, selectedDeadlineDate }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(updateProductionPlan(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}
