import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null
};

const slice = createSlice({
  name: 'invoiceList',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    createInvoiceList(state, action) {
      state.lists.items = [...state.lists.items, action.payload];
      state.lists.totalItems += 1;
    },
    updateInvoiceList(state, action) {
      const updatedInvoiceList = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedInvoiceList.id ? updatedInvoiceList : item));
    },
    deleteInvoiceList(state, action) {
      const invoiceListId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== invoiceListId);
      state.lists.totalItems -= 1;
    },
    progressInvoiceList(state, action) {
      const invoiceListId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== invoiceListId);
      state.lists.totalItems -= 1;
    }
  }
});

export const { getLists, createInvoiceList, updateInvoiceList, deleteInvoiceList, progressInvoiceList } = slice.actions;

export default slice.reducer;

export function getInvoiceListList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/invoiceList/list',
        params: { page, pageSize }
      };
      const response = await ApiService.get(objData);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getInvoiceOrderList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/invoiceOrderList/list',
        params: { page, pageSize }
      };
      const response = await ApiService.get(objData);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getInvoiceListInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (key === 'bomItems' && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, NewLists[key]);
        }
      });

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/invoiceList/invoiceListUpload`, data, config);

      if (response.status === 200) {
        dispatch(createInvoiceList(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getInvoiceListUpdate(NewLists, id, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const data = new FormData();
        Object.keys(NewLists).forEach((key) => {
          if (key === 'files' && NewLists[key][0]) {
            data.append('file', NewLists[key][0]);
          } else if (key === 'bomItems') {
            data.append(key, JSON.stringify(NewLists[key]));
          } else {
            data.append(key, NewLists[key]);
          }
        });
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/invoiceList/invoiceListUpdate`, data, config);
      } else {
        const objData = {
          path: '/invoiceList/update',
          data: NewLists
        };
        response = await ApiService.post(objData);
      }

      if (response.status === 200) {
        dispatch(updateInvoiceList(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getInvoiceListDelete(invoiceListId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/invoiceList/delete',
        data: { id: invoiceListId }
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteInvoiceList(invoiceListId));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}

export function getInvoiceListProgress(invoiceListId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/invoiceList/progress',
        data: { id: invoiceListId }
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(progressInvoiceList(invoiceListId));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
