// third-party
// import { FormattedMessage } from 'react-intl';

// assets
import {
  BuildOutlined,
  CalendarOutlined,
  CustomerServiceOutlined,
  FileTextOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  BankOutlined,
  InsertRowBelowOutlined,
  FormOutlined,
  CarOutlined,
  BarcodeOutlined,
  ClusterOutlined,
  GoldOutlined
} from '@ant-design/icons';

// icons
const icons = {
  BuildOutlined,
  CalendarOutlined,
  CustomerServiceOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  FileTextOutlined,
  BankOutlined,
  InsertRowBelowOutlined,
  FormOutlined,
  CarOutlined,
  BarcodeOutlined,
  ClusterOutlined,
  GoldOutlined
};
// ==============================|| MENU ITEMS - mes ||============================== //

const mes = {
  id: 'mes',
  title: 'Production',
  icon: icons.CalendarOutlined,
  type: 'group',
  children: [
    {
      id: 'invoices',
      title: '영업관리',
      url: '/mes/invoice',
      type: 'item',
      icon: icons.FileTextOutlined,
      breadcrumbs: false
    },
    {
      id: 'material',
      title: '자재관리',
      type: 'item',
      url: '/mes/material',
      icon: icons.AppstoreAddOutlined,
      breadcrumbs: false
    },
    {
      id: 'group-production',
      title: '생산관리',
      url: '/mes/productionPlan',
      type: 'item',
      icon: icons.ClusterOutlined,
      breadcrumbs: false
    },
    {
      id: 'quality',
      title: '품질관리',
      url: '/mes/quality',
      type: 'item',
      icon: icons.CustomerServiceOutlined,
      breadcrumbs: false
      // children: [
      //   {
      //     id: 'qualitative',
      //     title: '정성평가',
      //     url: '/mes/qualitative',
      //     type: 'item',
      //     breadcrumbs: false
      //   },
      //   {
      //     id: 'quantitative',
      //     title: '정량평가',
      //     url: '/mes/quantitative',
      //     type: 'item',
      //     breadcrumbs: false
      //   }
      // ]
    },
    {
      id: 'inventory',
      title: '재고관리',
      url: '/mes/inventory',
      type: 'item',
      icon: icons.GoldOutlined,
      breadcrumbs: false
    },
    {
      id: 'shipment',
      title: '출하관리',
      url: '/mes/shipment',
      type: 'item',
      icon: icons.CarOutlined,
      breadcrumbs: false
    }
  ]
};

export default mes;
