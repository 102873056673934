import axios from 'axios';
import ApiService from 'services/ApiService';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lists: [],
  error: null
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    updateCompany(state, action) {
      const updatedCompany = action.payload;
      state.lists = state.lists.map((item) => (item.id === updatedCompany.id ? updatedCompany : item));
    }
  }
});

export const { updateCompany } = slice.actions;
export default slice.reducer;

export function getCompanyUpdate(newData, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const formData = new FormData();
        Object.keys(newData).forEach((key) => {
          if (key !== 'addedFiles' && key !== 'deletedFiles') {
            formData.append(key, newData[key]);
          }
        });

        if (newData.addedFiles?.length) {
          newData.addedFiles.forEach((file) => formData.append('addedFiles', file));
        }

        if (newData.deletedFiles?.length) {
          formData.append('deletedFiles', JSON.stringify(newData.deletedFiles));
        }

        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/company/companyUpdate`, formData, {
          headers: { 'content-type': 'multipart/form-data', 'x-access-token': localStorage.getItem('serviceToken') }
        });
      } else {
        response = await ApiService.post({
          path: '/company/update',
          ...newData
        });
      }

      if (response.status === 200) {
        dispatch(slice.actions.updateCompany(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Update error:', error);
      dispatch(slice.actions.hasError(error.message));
      return { success: false, error: error.message };
    }
  };
}
