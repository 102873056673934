import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiService from 'services/ApiService';

const initialState = {
  lists: [],
  error: null
};

const slice = createSlice({
  name: 'purchases',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
    },
    createPurchases(state, action) {
      state.lists.push(action.payload);
    },
    updatePurchases(state, action) {
      const updatedPurchase = action.payload;
      const index = state.lists.findIndex((item) => item.id === updatedPurchase.id);
      if (index !== -1) {
        state.lists[index] = updatedPurchase;
      }
    },
    deletePurchases(state, action) {
      state.lists = state.lists.filter((item) => item.id !== action.payload);
    }
  }
});

export const { getLists, createPurchases, updatePurchases, deletePurchases } = slice.actions;
export default slice.reducer;

const config = {
  headers: {
    'content-type': 'multipart/form-data',
    'x-access-token': localStorage.getItem('serviceToken')
  }
};

function createFormData(data) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === 'purchaseEffortRelations') {
      formData.append(key, JSON.stringify(data[key]));
    } else if (data[key] !== undefined && data[key] !== null) {
      formData.append(key, data[key]);
    }
  });
  return formData;
}

export function getPurchasesList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/purchases/list' });
      dispatch(getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}

export function getPurchasesInsert(newPurchase) {
  return async (dispatch) => {
    try {
      const formData = createFormData(newPurchase);
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/purchases/purchasesUpload`, formData, config);

      if (response.status === 200) {
        dispatch(createPurchases(response.data));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || 'Failed to insert purchase');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}

export function getPurchasesUpdate(updatedPurchase, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const formData = createFormData(updatedPurchase);
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/purchases/purchasesUpdate`, formData, config);
      } else {
        response = await ApiService.post({ path: '/purchases/update', data: updatedPurchase });
      }

      if (response.status === 200) {
        dispatch(updatePurchases(response.data));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || 'Failed to update purchase');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}

export function getPurchasesDelete(purchasesId) {
  return async (dispatch) => {
    try {
      const response = await ApiService.post({
        path: '/purchases/delete',
        data: { id: purchasesId }
      });
      if (response.status === 200) {
        dispatch(deletePurchases(purchasesId));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || 'Failed to delete purchase');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}
