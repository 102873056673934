import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null,
  alertPopup: false
};

const slice = createSlice({
  name: 'moldShotCount',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    createMoldShotCount(state, action) {
      state.lists.items = [...state.lists.items, action.payload];
      state.lists.totalItems += 1;
    },
    createMoldShotCountExcel(state, action) {
      state.lists.items = [...state.lists.items, ...action.payload];
      state.lists.totalItems += action.payload.length;
    },
    updateMoldShotCount(state, action) {
      const updatedMoldShotCount = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedMoldShotCount.id ? updatedMoldShotCount : item));
    },
    deleteMoldShotCount(state, action) {
      const moldShotCountId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== moldShotCountId);
      state.lists.totalItems -= 1;
    },
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

export const { getLists, createMoldShotCount, createMoldShotCountExcel, updateMoldShotCount, deleteMoldShotCount, alertPopupToggle } =
  slice.actions;

export default slice.reducer;

export function getMoldShotCountList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/moldShotCount/list',
        params: {
          page,
          pageSize
        }
      };

      // console.log('objData', objData);
      const response = await ApiService.get(objData);
      // console.log('response', response);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getMoldShotCountUpsert(NewLists) {
  console.log('NewLists', NewLists);
  return async (dispatch) => {
    try {
      const data = NewLists;

      console.log('data', data);

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/moldShotCount/upsert`, data, config);
      console.log('response', response);
      if (response.status === 200 && response.data) {
        // Assuming `createMoldShotCount` is the action that handles the updated data
        dispatch(slice.actions.createMoldShotCount(response.data));
        return { success: true, data: response.data }; // Return the success object
      } else {
        return { success: false, message: response.data?.message || 'Failed to update' };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, message: error.message || 'An error occurred' };
    }
  };
}

export function getMoldShotCountInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'products') {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, typeof NewLists[key] === 'object' ? JSON.stringify(NewLists[key]) : NewLists[key]);
        }
      });
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/moldShotCount/moldShotCountUpload`, data, config);
      if (response.status === 200) {
        dispatch(createMoldShotCount(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getMoldShotCountUpdate(NewLists, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const data = new FormData();
        Object.keys(NewLists).forEach((key) => {
          if (key === 'products') {
            data.append(key, JSON.stringify(NewLists[key]));
          } else if (key === 'files' && NewLists[key] && NewLists[key][0]) {
            data.append('file', NewLists[key][0]);
          } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
            data.append(key, typeof NewLists[key] === 'object' ? JSON.stringify(NewLists[key]) : NewLists[key]);
          }
        });
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/moldShotCount/moldShotCountUpdate`, data, config);
      } else {
        const objData = {
          path: '/moldShotCount/update',
          data: NewLists
        };
        response = await ApiService.post(objData);
      }

      if (response.status === 200) {
        dispatch(updateMoldShotCount(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getMoldShotCountDelete(moldShotCountId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/moldShotCount/delete',
        data: { id: moldShotCountId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteMoldShotCount(moldShotCountId));
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
