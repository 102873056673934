import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null
};

const slice = createSlice({
  name: 'quality',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    getEndLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    getSettingLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    getSingleList(state, action) {
      state.list = action.payload;
    },
    createQuality(state, action) {
      const newQuality = action.payload;
      state.lists.items = [newQuality, ...state.lists.items];
      state.lists.totalItems += 1;
    },
    updateQuality(state, action) {
      const updatedQuality = action.payload;
      state.lists.items = state.lists.items.map((item) => {
        if (item.id === updatedQuality.id) {
          // m_quality_link 배열 업데이트
          const updatedLinks = updatedQuality.m_quality_link || [];
          const existingLinks = item.m_quality_link || [];

          const mergedLinks = [
            ...existingLinks.filter((link) => !updatedLinks.find((updatedLink) => updatedLink.id === link.id)),
            ...updatedLinks
          ];

          return {
            ...item,
            ...updatedQuality,
            m_quality_link: mergedLinks
          };
        }
        return item;
      });
    },
    upsertQualitySetting(state, action) {
      const upsertQuality = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === upsertQuality.id ? upsertQuality : item));
    },
    deleteQuality(state, action) {
      const qualityId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== qualityId);
      state.lists.totalItems -= 1;
    },
    endQuality(state, action) {
      const qualityId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== qualityId);
      state.lists.totalItems -= 1;
    },
    deleteQualitySetting(state, action) {
      const qualitySettingId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== qualitySettingId);
      state.lists.totalItems -= 1;
    },
    deleteQualityLink(state, action) {
      const qualityLinkId = action.payload;
      state.lists.items = state.lists.items.map((item) => ({
        ...item,
        m_quality_link: item.m_quality_link?.filter((link) => link.id !== qualityLinkId) || []
      }));
    },
    getQualityDetail(state, action) {
      state.list = action.payload;
    },
    getOneProductQualityDetail(state, action) {
      state.list = action.payload;
    },
    resetQualityLists(state) {
      state.lists = {
        items: [],
        totalItems: 0
      };
      state.list = null;
      state.error = null;
    }
  }
});

export const {
  getLists,
  getEndLists,
  getSingleList,
  createQuality,
  updateQuality,
  endQuality,
  deleteQuality,
  deleteQualitySetting,
  deleteQualityLink,
  getSettingLists,
  upsertQualitySetting,
  getQualityDetail,
  getOneProductQualityDetail,
  resetQualityLists
} = slice.actions;

export default slice.reducer;

export function fetchQualityDetail(id) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/quality/findOne',
        id: id
      };
      const response = await ApiService.get(objData);
      if (response.status === 200) {
        console.log('response', response);
        dispatch(getQualityDetail(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function fetchOneProductQualityDetail(id) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/quality/findOneProduct',
        id: id
      };
      const response = await ApiService.get(objData);
      if (response.status === 200) {
        dispatch(getOneProductQualityDetail(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getQualityList() {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/quality/list'
      };
      const response = await ApiService.get(objData);
      // console.log('response', response);
      dispatch(getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getQualityEndList() {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/quality/endList'
      };
      const response = await ApiService.get(objData);
      // console.log('response', response);
      dispatch(getEndLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getQualitySettingList() {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/quality/settingList'
      };
      const response = await ApiService.get(objData);
      dispatch(getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getQualityInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();

      Object.keys(NewLists).forEach((key) => {
        if (['addedFiles', 'qualitativeResult', 'quantitativeResult'].includes(key) && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (NewLists[key] != null) {
          data.append(key, NewLists[key]);
        }
      });

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/quality/qualityUpload`, data, config);

      // console.log('API response data:', response);

      if (response.status === 200 && response.data && response.data.success) {
        dispatch(slice.actions.createQuality(response.data.data));
        return { success: true, data: response.data.data };
      } else {
        // console.error('API request failed:', response.data.message);
        return { success: false, message: response.data.message || 'Unknown error occurred' };
      }
    } catch (error) {
      // console.error('Error in getQualityInsert:', error);
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error: error.message || 'An unknown error occurred' };
    }
  };
}

export function qualityNoPlanInsert(NewLists) {
  // console.log('NewLists:', NewLists);
  return async (dispatch) => {
    try {
      const data = new FormData();

      Object.keys(NewLists).forEach((key) => {
        if (['addedFiles', 'qualitativeResult', 'quantitativeResult'].includes(key) && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (NewLists[key] != null) {
          data.append(key, NewLists[key]);
        }
      });

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/quality/qualityNoPlanInsert`, data, config);

      // console.log('API response data:', response.data);

      if (response.status === 200 && response.data && response.data.success) {
        dispatch(createQuality(response.data));
        return { success: true, data: response.data.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error: error.message || 'An unknown error occurred' };
    }
  };
}

export function getQualityUpdate(NewLists) {
  console.log('NewLists:', NewLists);
  return async (dispatch) => {
    try {
      const data = new FormData();

      Object.keys(NewLists).forEach((key) => {
        if (['addedFiles', 'deletedFiles', 'qualitativeResult', 'quantitativeResult'].includes(key) && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (NewLists[key] != null) {
          data.append(key, NewLists[key]);
        }
      });

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/quality/qualityUpdate`, data, config);

      console.log('API response data:', response);

      if (response.status === 200 && response.data && response.data.success) {
        dispatch(slice.actions.updateQuality(response.data));
        return { success: true, data: response.data.data };
      } else {
        console.log('Update failed. Response:', response);
        return { success: false, message: response.data.message || 'Update failed' };
      }
    } catch (error) {
      console.error('Error in getQualityUpdate:', error);
      console.error('Error response:', error.response);
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error: error.message || 'An error occurred' };
    }
  };
}

export function getQuality(id) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/api/quality/details', { id });
      dispatch(getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getQualitySingleList(qualityId) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/api/quality/list/single', qualityId);
      dispatch(getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getQualitySettingUpsert(NewLists) {
  console.log('NewLists:', NewLists);
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (key === 'qualitativeStandards' && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (key === 'quantitativeStandards' && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, NewLists[key]);
        }
      });

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/quality/qualitySettingUpsert`, data, config);

      console.log('API response status:', response.status);

      if (response.status === 200) {
        dispatch(upsertQualitySetting(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getQualitySettingNew(NewLists) {
  console.log('NewLists:', NewLists);
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (key === 'qualitativeStandards' && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (key === 'quantitativeStandards' && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, NewLists[key]);
        }
      });
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/quality/qualitySettingNew`, data, config);

      console.log('API response status:', response.status);

      if (response.status === 200) {
        dispatch(upsertQualitySetting(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getQualityEnd(qualityId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/quality/end',
        data: { id: qualityId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(endQuality(qualityId));
        return response.data;
      } else {
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}

export function getQualityRestore(qualityId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/quality/restore',
        data: { id: qualityId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(endQuality(qualityId));
        return response.data;
      } else {
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}

export function getQualityDelete(qualityId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/quality/delete',
        data: { id: qualityId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteQuality(qualityId));
        return response.data;
      } else {
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}

export function getQualityLinkDelete(qualityLinkId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/quality/deleteLink',
        data: { id: qualityLinkId }
      };
      const response = await ApiService.post(objData);

      if (response.status === 200) {
        dispatch(deleteQualityLink(qualityLinkId));
        return response.data;
      } else {
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}

export function getQualitySettingDelete(qualitySettingId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/quality/settingDelete',
        data: { id: qualitySettingId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteQualitySetting(qualitySettingId));
        return response.data;
      } else {
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
