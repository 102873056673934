import ApiService from 'services/ApiService';
import { createSlice } from '@reduxjs/toolkit';

// const token = window.localStorage.getItem('serviceToken');
// const config = {
//   headers: {
//     'content-type': 'multipart/form-data',
//     'x-access-token': token
//   }
// };

const initialState = {
  lists: [],
  error: null
};

const slice = createSlice({
  name: 'diecastingInfo',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
    },
    getListsMenu(state, action) {
      state.lists = action.payload;
    }
  }
});

export const { getLists, getListsMenu } = slice.actions;
export default slice.reducer;

export function getDiecastingInfoList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/diecastingInfo/list' });
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}

export function getDiecastingInfoListMenu() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/diecastingInfo/listMenu' });
      dispatch(slice.actions.getListsMenu(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}
