import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';

const initialState = {
  list: [],
  menu: [],
  error: null
};

const slice = createSlice({
  name: 'hwaseongTechInfo',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getListSuccess(state, action) {
      state.list = action.payload;
    },
    getMenuSuccess(state, action) {
      state.menu = action.payload;
    }
  }
});

export const { getListSuccess, getMenuSuccess } = slice.actions;
export default slice.reducer;

export function getHwaseongTechInfoList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/hwaseongTechInfo/list' });
      dispatch(getListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}

export function getHwaseongTechInfoListMenu() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/hwaseongTechInfo/menu' });
      dispatch(getMenuSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}
