import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null
};

const diecastingAlmTableSlice = createSlice({
  name: 'diecastingAlmTable',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload.lists;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    }
  }
});

export const { getLists } = diecastingAlmTableSlice.actions;
export default diecastingAlmTableSlice.reducer;

export function getDiecastingAlmTableList(page, pageSize) {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({
        path: '/diecastingAlm/list',
        params: { page, pageSize }
      });

      dispatch(
        getLists({
          lists: {
            items: response.data.items,
            totalItems: response.data.totalItems
          },
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(diecastingAlmTableSlice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}
