import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null
};

const slice = createSlice({
  name: 'factoryMachine',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    getNoPageLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    createFactoryMachine(state, action) {
      state.lists.items = [...state.lists.items, action.payload];
      state.lists.totalItems += 1;
    },
    updateFactoryMachine(state, action) {
      const updatedMachine = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedMachine.id ? updatedMachine : item));
    },
    deleteFactoryMachine(state, action) {
      const machineId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== machineId);
      state.lists.totalItems -= 1;
    }
  }
});

export const { getLists, getNoPageLists, createFactoryMachine, updateFactoryMachine, deleteFactoryMachine } = slice.actions;

export default slice.reducer;

export function getFactoryMachineList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/factoryMachine/list',
        params: { page, pageSize }
      };
      const response = await ApiService.get(objData);
      console.log('response', response);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getFactoryMachineNoPageList() {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/factoryMachine/noPageList'
      };
      const response = await ApiService.get(objData);
      dispatch(getNoPageLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}

export function getFactoryMachineInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (key === 'plc') {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, NewLists[key]);
        }
      });

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/factoryMachine/factoryMachineUpload`, data, config);

      if (response.status === 200) {
        dispatch(createFactoryMachine(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getFactoryMachineUpdate(NewLists, id, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const data = new FormData();
        Object.keys(NewLists).forEach((key) => {
          if (key === 'files' && NewLists[key][0]) {
            data.append('file', NewLists[key][0]);
          } else if (key === 'plc') {
            data.append(key, JSON.stringify(NewLists[key]));
          } else {
            data.append(key, NewLists[key]);
          }
        });
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/factoryMachine/factoryMachineUpdate`, data, config);
      } else {
        const objData = {
          path: '/factoryMachine/update',
          data: NewLists
        };
        response = await ApiService.post(objData);
      }

      if (response.status === 200) {
        dispatch(updateFactoryMachine(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getFactoryMachineDelete(machineId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/factoryMachine/delete',
        data: { id: machineId }
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteFactoryMachine(machineId));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
