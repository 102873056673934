import ApiService from 'services/ApiService';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lists: {
    diecasting: [],
    diecastingInfo: [],
    diecastingAlm: []
  },
  error: null
};

const slice = createSlice({
  name: 'diecasting',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getDiecastingListSuccess(state, action) {
      state.lists.diecasting = action.payload;
    },
    getDiecastingInfoListSuccess(state, action) {
      state.lists.diecastingInfo = action.payload;
    },
    getDiecastingAlmListSuccess(state, action) {
      state.lists.diecastingAlm = action.payload;
    }
  }
});

export const { getDiecastingListSuccess, getDiecastingInfoListSuccess, getDiecastingAlmListSuccess } = slice.actions;

export default slice.reducer;

export function getDiecastingList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/diecasting/list' });
      dispatch(slice.actions.getDiecastingListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}

export function getDiecastingInfoList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/diecasting/info_list' });
      dispatch(slice.actions.getDiecastingInfoListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}

export function getDiecastingAlmList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/diecasting/alm_list' });
      dispatch(slice.actions.getDiecastingAlmListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}
