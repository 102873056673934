import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiService from 'services/ApiService';

const initialState = {
  lists: [],
  error: null
};

const slice = createSlice({
  name: 'qnaComments',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
    },
    createQnaComments(state, action) {
      state.lists.push(action.payload);
    },
    updateQnaComments(state, action) {
      const updatedComment = action.payload;
      const index = state.lists.findIndex((item) => item.id === updatedComment.id);
      if (index !== -1) {
        state.lists[index] = updatedComment;
      }
    },
    deleteQnaComments(state, action) {
      state.lists = state.lists.filter((item) => item.id !== action.payload);
    }
  }
});

export const { getLists, createQnaComments, updateQnaComments, deleteQnaComments } = slice.actions;
export default slice.reducer;

const config = {
  headers: {
    'content-type': 'multipart/form-data',
    'x-access-token': localStorage.getItem('serviceToken')
  }
};

function createFormData(data) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (data[key] !== undefined) {
      formData.append(key, data[key]);
    }
  });
  return formData;
}

export function getQnaCommentsList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/qnaComments/list' });
      dispatch(getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}

export function getQnaCommentsInsert(newComment) {
  return async (dispatch) => {
    try {
      const formData = createFormData(newComment);
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/qnaComments/qnaCommentsUpload`, formData, config);

      if (response.status === 200) {
        dispatch(createQnaComments(response.data));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || 'Failed to insert QnA comment');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}

export function getQnaCommentsUpdate(updatedComment, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const formData = createFormData(updatedComment);
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/qnaComments/qnaCommentsUpdate`, formData, config);
      } else {
        response = await ApiService.post({ path: '/qnaComments/update', data: updatedComment });
      }

      if (response.status === 200) {
        dispatch(updateQnaComments(response.data));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || 'Failed to update QnA comment');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}

export function getQnaCommentsDelete(qnaCommentsId) {
  return async (dispatch) => {
    try {
      const response = await ApiService.post({
        path: '/qnaComments/delete',
        data: { id: qnaCommentsId }
      });
      if (response.status === 200) {
        dispatch(deleteQnaComments(qnaCommentsId));
        return { success: true };
      } else {
        throw new Error(response.data.message || 'Failed to delete QnA comment');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}
