import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiService from 'services/ApiService';

const initialState = {
  lists: [],
  error: null
};

const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
    },
    createNews(state, action) {
      state.lists.push(action.payload);
    },
    updateNews(state, action) {
      const updatedNews = action.payload;
      const index = state.lists.findIndex((item) => item.id === updatedNews.id);
      if (index !== -1) {
        state.lists[index] = updatedNews;
      }
    },
    deleteNews(state, action) {
      state.lists = state.lists.filter((item) => item.id !== action.payload);
    }
  }
});

export const { getLists, createNews, updateNews, deleteNews } = slice.actions;
export default slice.reducer;

const config = {
  headers: {
    'content-type': 'multipart/form-data',
    'x-access-token': localStorage.getItem('serviceToken')
  }
};

export function getNewsList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/news/list' });
      dispatch(getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}

export function getNewsOGList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/news/OGlist' });
      if (Array.isArray(response.data)) {
        dispatch(getLists(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'Failed to fetch OG list'));
    }
  };
}

export function getNewsOGOpenList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/news/OGOpenlist' });
      if (Array.isArray(response.data)) {
        dispatch(getLists(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'Failed to fetch OG open list'));
    }
  };
}

export function getNewsBlockOGList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/news/BlockOGlist' });
      if (Array.isArray(response.data)) {
        dispatch(getLists(response.data));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'Failed to fetch block OG list'));
    }
  };
}

export function getNewsInsert(newNews) {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      Object.keys(newNews).forEach((key) => {
        formData.append(key, newNews[key]);
      });

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/news/newsUpload`, formData, config);

      if (response.status === 200) {
        dispatch(createNews(response.data));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || 'Failed to insert news');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}

export function getNewsUpdate(updatedNews) {
  return async (dispatch) => {
    try {
      const response = await ApiService.post({
        path: '/news/update',
        data: updatedNews
      });

      if (response.status === 200) {
        dispatch(updateNews(response.data));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || 'Failed to update news');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}

export function getNewsDelete(newsId) {
  return async (dispatch) => {
    try {
      const response = await ApiService.post({
        path: '/news/delete',
        data: { id: newsId }
      });
      if (response.status === 200) {
        dispatch(deleteNews(newsId));
        return { success: true };
      } else {
        throw new Error(response.data.message || 'Failed to delete news');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}
