import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    getSingleList(state, action) {
      state.list = action.payload;
    },
    createProduct(state, action) {
      state.lists.items = [...state.lists.items, action.payload];
      state.lists.totalItems += 1;
    },
    createProductExcel(state, action) {
      state.lists.items = [...state.lists.items, ...action.payload];
      state.lists.totalItems += action.payload.length;
    },
    updateProduct(state, action) {
      const updatedProduct = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedProduct.id ? updatedProduct : item));
    },
    deleteProduct(state, action) {
      const productId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== productId);
      state.lists.totalItems -= 1;
    }
  }
});

export const { getLists, getSingleList, createProduct, updateProduct, deleteProduct, createProductExcel } = slice.actions;

export default slice.reducer;

export function getProductList() {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/product/list'
        // params: { page, pageSize }
      };
      const response = await ApiService.get(objData);
      console.log('response', response.data);
      dispatch(getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getProductInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (key === 'bomItems' && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (key === 'lineBomItems' && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, typeof NewLists[key] === 'object' ? JSON.stringify(NewLists[key]) : NewLists[key]);
        }
      });
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/product/productUpload`, data, config);
      if (response.status === 200) {
        dispatch(createProduct(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getProductSingleList(productId) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/api/product/list/single', { id: productId });
      dispatch(getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getProductUpdate(NewLists, id, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const data = new FormData();
        Object.keys(NewLists).forEach((key) => {
          if (key === 'files' && NewLists[key] && NewLists[key][0]) {
            data.append('file', NewLists[key][0]);
          } else if (key === 'bomItems' && Array.isArray(NewLists[key])) {
            data.append(key, JSON.stringify(NewLists[key]));
          } else if (key === 'lineBomItems' && Array.isArray(NewLists[key])) {
            data.append(key, JSON.stringify(NewLists[key]));
          } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
            data.append(key, typeof NewLists[key] === 'object' ? JSON.stringify(NewLists[key]) : NewLists[key]);
          }
        });
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/product/productUpdate`, data, config);
      } else {
        const objData = {
          path: '/product/update',
          data: NewLists
        };
        response = await ApiService.post(objData);
      }

      if (response.status === 200) {
        dispatch(updateProduct(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getProductExcelUpload(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      data.append('excels', JSON.stringify(NewLists.excels));
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/product/excelUpload`, data, config);
      if (response.status === 200) {
        dispatch(createProductExcel(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getProductDelete(productId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/product/delete',
        data: { id: productId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteProduct(productId));
        return { success: true, data: response.data };
        // return response.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
