import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
// import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - Monitoring
// const MonitoringDefault = Loadable(lazy(() => import('pages/monitoring/default')));
// const MonitoringAnalytics = Loadable(lazy(() => import('pages/monitoring/analytics')));
// const MachineDetail = Loadable(lazy(() => import('pages/monitoring/machineDetail')));
// const CollapseLogin = Loadable(lazy(() => import('pages/monitoring/collapse/collapseLogin')));
// const SelectFolder = Loadable(lazy(() => import('pages/monitoring/collapse/selectFolder')));
// const OperationChek = Loadable(lazy(() => import('pages/monitoring/collapse/operationChek')));
// const MachineAnalytics = Loadable(lazy(() => import('pages/monitoring/machineAnalytics')));
// const MachineMonitoring = Loadable(lazy(() => import('pages/monitoring/machineMonitoring')));

const DiecastingList = Loadable(lazy(() => import('pages/custom-pages/diecasting/list')));
const DiecastingInfoList = Loadable(lazy(() => import('pages/custom-pages/diecasting/list_info')));
const DiecastingAlmList = Loadable(lazy(() => import('pages/custom-pages/diecasting/list_alm')));
const DiecastingDetail = Loadable(lazy(() => import('pages/custom-pages/diecasting/details')));
const DiecastingCsv = Loadable(lazy(() => import('pages/custom-pages/diecasting/details_csv')));

const MeltingFurnaceList = Loadable(lazy(() => import('pages/custom-pages/diecasting/meltingFurnace/Temperature')));

const CustomMonitoringMain = Loadable(lazy(() => import('pages/custom-pages/default')));

const HwaseongTechList = Loadable(lazy(() => import('pages/custom-pages/hwaseongTech/list')));
const HwaseongTechDetail = Loadable(lazy(() => import('pages/custom-pages/hwaseongTech/details')));
const HwaseongTechDetailCsv = Loadable(lazy(() => import('pages/custom-pages/hwaseongTech/details_csv')));

// ==============================|| MONITORING ROUTING ||============================== //

const MonitoringRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'monitoring',
          children: [
            // {
            //   path: 'dashboard',
            //   element: <MonitoringDefault />
            // },
            // {
            //   path: 'analytics',
            //   element: <MonitoringAnalytics />
            // },
            // {
            //   path: 'machineAnalytics',
            //   element: <MachineAnalytics />
            // },
            // {
            //   path: 'machineDetail',
            //   element: <MachineDetail />
            // },
            // {
            //   path: 'machineMonitoring',
            //   element: <MachineMonitoring />
            // },
            // {
            //   path: 'collapseLogin',
            //   element: <CollapseLogin />
            // },
            // {
            //   path: 'selectFolder',
            //   element: <SelectFolder />
            // },
            // {
            //   path: 'operationChek',
            //   element: <OperationChek />
            // },
            {
              path: 'diecasting_list',
              element: <DiecastingList />
            },
            {
              path: 'diecasting_info_list',
              element: <DiecastingInfoList />
            },
            {
              path: 'diecasting_alm_list',
              element: <DiecastingAlmList />
            },
            {
              path: 'diecasting_details/:name',
              element: <DiecastingDetail />
            },
            {
              path: 'diecasting_csv/:name',
              element: <DiecastingCsv />
            },
            {
              path: 'meltingFurnace_list',
              element: <MeltingFurnaceList />
            },
            {
              path: 'custom_monitoring_main',
              element: <CustomMonitoringMain />
            },
            {
              path: 'hwaseongTech_list',
              element: <HwaseongTechList />
            },
            {
              path: 'hwaseongTech_details/:name',
              element: <HwaseongTechDetail />
            },
            {
              path: 'hwaseongTech_csv/:name',
              element: <HwaseongTechDetailCsv />
            }
          ]
        }
      ]
    }
  ]
};

export default MonitoringRoutes;
