import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null
};

const slice = createSlice({
  name: 'customerManager',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    createCustomerManager(state, action) {
      state.lists.items = [...state.lists.items, action.payload];
      state.lists.totalItems += 1;
    },
    updateCustomerManager(state, action) {
      const updatedManager = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedManager.id ? updatedManager : item));
    },
    deleteCustomerManager(state, action) {
      const managerId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== managerId);
      state.lists.totalItems -= 1;
    }
  }
});

export const { getLists, createCustomerManager, updateCustomerManager, deleteCustomerManager } = slice.actions;

export default slice.reducer;

// export function getManagersByCompanyId(c_company_id) {
//   return async (dispatch) => {
//     try {
//       const objData = {
//         path: `/customerManager/byCompany`,
//         params: { c_company_id }
//       };
//       const response = await ApiService.get(objData);
//       dispatch(getLists(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error.toString()));
//     }
//   };
// }

export function getManagersByCompanyId(c_company_id) {
  return async (dispatch) => {
    try {
      const objData = {
        path: `/customerManager/byCompany?c_company_id=${c_company_id}`
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomerManagerList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/customerManager/list',
        params: { page, pageSize }
      };
      const response = await ApiService.get(objData);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getCustomerManagerInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, NewLists[key]);
        }
      });

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/customerManager/customerManagerUpload`, data, config);

      if (response.status === 200) {
        dispatch(createCustomerManager(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getCustomerManagerUpdate(NewLists, id, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const data = new FormData();
        Object.keys(NewLists).forEach((key) => {
          if (key === 'files' && NewLists[key][0]) {
            data.append('file', NewLists[key][0]);
          } else {
            data.append(key, NewLists[key]);
          }
        });
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/customerManager/customerManagerUpdate`, data, config);
      } else {
        const objData = {
          path: '/customerManager/update',
          data: NewLists
        };
        response = await ApiService.post(objData);
      }

      if (response.status === 200) {
        dispatch(updateCustomerManager(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getCustomerManagerDelete(managerId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/customerManager/delete',
        data: { id: managerId }
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteCustomerManager(managerId));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
