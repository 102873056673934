import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// const AppCustomerList = Loadable(lazy(() => import('pages/apps/customer/list')));
// const AppCustomerCard = Loadable(lazy(() => import('pages/apps/customer/card')));

const AppCustomerCompanyList = Loadable(lazy(() => import('pages/customer/customerCompany/list')));
const AppCustomerCompanyCreate = Loadable(lazy(() => import('pages/customer/customerCompany/create')));
const AppCustomerCompanyEdit = Loadable(lazy(() => import('pages/customer/customerCompany/edit')));

const AppCustomerManagerList = Loadable(lazy(() => import('pages/customer/customerManager/list')));
const AppCustomerManagerCreate = Loadable(lazy(() => import('pages/customer/customerManager/create')));
const AppCustomerManagerEdit = Loadable(lazy(() => import('pages/customer/customerManager/edit')));

// ==============================|| MAIN ROUTING ||============================== //

const customerRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'customer',
          children: [
            {
              path: 'customerCompany',
              element: <AppCustomerCompanyList />
            },
            {
              path: 'customerCompany_create',
              element: <AppCustomerCompanyCreate />
            },
            {
              path: 'customerCompany_edit/:id',
              element: <AppCustomerCompanyEdit />
            },
            {
              path: 'customerManager',
              element: <AppCustomerManagerList />
            },
            {
              path: 'customerManager_create',
              element: <AppCustomerManagerCreate />
            },
            {
              path: 'customerManager_edit/:id',
              element: <AppCustomerManagerEdit />
            }
            // {
            //   path: 'customer-card',
            //   element: <AppCustomerCard />
            // }
          ]
        }
      ]
    }
  ]
};

export default customerRoutes;
