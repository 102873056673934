import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('serviceToken');
    if (token) {
      config.headers['x-access-token'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;

// ApiAdaptor.js;
// import axios from 'axios';

// const instance = axios.create({
//   baseURL: process.env.REACT_APP_API_BASE_URL,
//   headers: {
//     'Content-Type': 'application/json'
//   },
//   timeout: 30000 // 30초 타임아웃 설정
// });

// instance.interceptors.request.use(
//   (config) => {
//     const token = window.localStorage.getItem('serviceToken');
//     if (token) {
//       config.headers['x-access-token'] = token;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // 응답 인터셉터 추가
// instance.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;

//     // 네트워크 오류나 타임아웃의 경우 재시도
//     if (error.message === 'Network Error' || error.code === 'ECONNABORTED') {
//       if (!originalRequest._retry) {
//         originalRequest._retry = true;
//         return instance(originalRequest);
//       }
//     }

//     return Promise.reject(error);
//   }
// );

// export default instance;
