import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiService from 'services/ApiService';

const initialState = {
  lists: [],
  error: null
};

const slice = createSlice({
  name: 'versionInfo',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
    },
    createVersionInfo(state, action) {
      state.lists.push(action.payload);
    },
    updateVersionInfo(state, action) {
      const updatedInfo = action.payload;
      const index = state.lists.findIndex((item) => item.id === updatedInfo.id);
      if (index !== -1) {
        state.lists[index] = updatedInfo;
      }
    },
    deleteVersionInfo(state, action) {
      state.lists = state.lists.filter((item) => item.id !== action.payload);
    }
  }
});

export const { getLists, createVersionInfo, updateVersionInfo, deleteVersionInfo } = slice.actions;
export default slice.reducer;

const config = {
  headers: {
    'content-type': 'multipart/form-data',
    'x-access-token': localStorage.getItem('serviceToken')
  }
};

function createFormData(data) {
  const formData = new FormData();
  const fields = ['version_no', 'release_date', 'description', 'release_manager', 'additional_info'];
  fields.forEach((field) => {
    if (data[field] !== undefined) {
      formData.append(field, data[field]);
    }
  });
  if (data.files && data.files[0]) {
    formData.append('file', data.files[0]);
  }
  return formData;
}

export function getVersionInfoList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/versionInfo/list' });
      dispatch(getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}

export function getVersionInfoInsert(newInfo) {
  return async (dispatch) => {
    try {
      const formData = createFormData(newInfo);
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/versionInfo/versionInfoUpload`, formData, config);

      if (response.status === 200) {
        dispatch(createVersionInfo(response.data));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || 'Failed to insert version info');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}

export function getVersionInfoUpdate(updatedInfo, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const formData = createFormData(updatedInfo);
        formData.append('id', updatedInfo.id);
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/versionInfo/versionInfoUpdate`, formData, config);
      } else {
        response = await ApiService.post({ path: '/versionInfo/update', data: updatedInfo });
      }

      if (response.status === 200) {
        dispatch(updateVersionInfo(response.data));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || 'Failed to update version info');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}

export function getVersionInfoDelete(versionInfoId) {
  return async (dispatch) => {
    try {
      const response = await ApiService.post({
        path: '/versionInfo/delete',
        data: { id: versionInfoId }
      });
      if (response.status === 200) {
        dispatch(deleteVersionInfo(versionInfoId));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || 'Failed to delete version info');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}
