import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';

const initialState = {
  lists: [],
  error: null
};

const slice = createSlice({
  name: 'productionCarbon',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
    }
  }
});

export const { getLists } = slice.actions;
export default slice.reducer;

export function getProductionCarbonList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/production/list' });
      dispatch(getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}
