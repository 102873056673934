import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    categories: [],
    criteria: [],
    inspectionItems: [],
    methods: []
  },
  categories: [],
  criteria: [],
  inspectionItems: [],
  methods: [],
  error: null,
  list: null
};

const singularToPlural = (table) => {
  switch (table) {
    case 'category':
      return 'categories';
    case 'criterion':
      return 'criteria';
    case 'inspectionItem':
      return 'inspectionItems';
    case 'method':
      return 'methods';
    default:
      return table;
  }
};

const slice = createSlice({
  name: 'qualitativeStandard',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      // 서버에서 반환하는 데이터 구조에 따라 적절히 수정
      state.lists = {
        categories: action.payload.categories || [],
        criteria: action.payload.criteria || [],
        inspectionItems: action.payload.inspectionItems || [],
        methods: action.payload.methods || []
      };
    },
    createQualitativeStandard(state, action) {
      Object.keys(action.payload).forEach((table) => {
        if (state.lists[table]) {
          const updatedItem = action.payload[table];
          state.lists[table] = state.lists[table].map((item) => (item.id === updatedItem.id ? { ...item, ...updatedItem } : item));
        }
      });
    },
    updateQualitativeStandard(state, action) {
      Object.keys(action.payload).forEach((table) => {
        if (state.lists[table]) {
          const updatedItem = action.payload[table];
          state.lists[table] = state.lists[table].map((item) => (item.id === updatedItem.id ? { ...item, ...updatedItem } : item));
        }
      });
    },
    deleteQualitativeStandard(state, action) {
      const { table, id } = action.payload;
      const pluralTable = singularToPlural(table);

      if (state.lists[pluralTable]) {
        state.lists[pluralTable] = state.lists[pluralTable].filter((item) => item.id !== id);
      } else {
        console.error(`Table ${pluralTable} not found in state`);
      }
    },
    updateList(state, action) {
      const { listName, items } = action.payload;
      if (state.lists[listName]) {
        state.lists[listName] = items;
      }
    },
    fetchStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchSuccess(state, action) {
      state.loading = false;
      state[action.payload.type] = action.payload.data;
    },
    fetchError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    addItem(state, action) {
      const { type, item } = action.payload;
      state[type].push(item);
    },
    insertQualitative(state, action) {
      const { type, item } = action.payload;
      if (state[type]) {
        state[type] = [...state[type], item];
      }
    }
  }
});

export const {
  getLists,
  createQualitativeStandard,
  updateQualitativeStandard,
  deleteQualitativeStandard,
  insertQualitative,
  fetchStart,
  fetchError,
  fetchSuccess,
  addItem,
  updateList // 새로 추가된 액션
} = slice.actions;

export default slice.reducer;

export function getQualitativeStandardList() {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/qualitativeStandard/list'
      };
      const response = await ApiService.get(objData);
      // console.log('API response:', response);

      // 서버 응답 구조에 따라 적절히 수정
      const normalizedData = {
        categories: response.data.qualitative?.categories || [],
        criteria: response.data.qualitative?.criteria || [],
        inspectionItems: response.data.qualitative?.inspectionItems || [],
        methods: response.data.qualitative?.methods || []
      };

      dispatch(getLists(normalizedData));
    } catch (error) {
      console.error('Error fetching qualitative standard list:', error);
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export const fetchQualitativeStandardItem = (type) => async (dispatch) => {
  // console.log('Fetching qualitative standard item:', type);
  dispatch(fetchStart());
  try {
    const objData = {
      path: `/qualitativeStandard/${type}`
    };

    const response = await ApiService.get(objData);

    dispatch(fetchSuccess({ type, data: response.data }));
  } catch (error) {
    dispatch(fetchError(error.toString()));
  }
};

export function getQualitativeStandardInsert(newItem) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      const table = Object.keys(newItem)[0];
      data.append(table, newItem[table]);

      // console.log('New item:', newItem);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/qualitativeStandard/qualitativeStandardUpload`,
        data,
        config
      );
      if (response.status === 200) {
        dispatch(createQualitativeStandard({ table, item: response.data }));
        // console.log('Server response:', response);
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getQualitativeStandardUpdate(updatedItem) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      const table = Object.keys(updatedItem)[0];
      data.append('id', updatedItem[table].id);
      data.append(table, updatedItem[table].value);
      data.append('flag', 'Y');

      console.log('Sending update data:', Object.fromEntries(data));

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/qualitativeStandard/qualitativeStandardUpdate`,
        data,
        config
      );
      if (response.data.success) {
        dispatch(updateQualitativeStandard(response.data.data));
        return { success: true, data: response.data.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getQualitativeStandardDelete(table, id) {
  return async (dispatch) => {
    try {
      console.log('Sending delete request:', { table, id });
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/qualitativeStandard/delete`,
        { table, id },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
          }
        }
      );
      // console.log('Delete response:', response.data);
      if (response.data.success) {
        // 테이블 이름을 단수형으로 통일
        const singularTable = table.endsWith('ies') ? table.slice(0, -3) + 'y' : table.endsWith('s') ? table.slice(0, -1) : table;
        dispatch(deleteQualitativeStandard({ table: singularTable, id }));
        return { success: true, data: response.data.data };
      } else {
        console.error('Delete failed:', response.data.message);
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Delete error:', error.response?.data || error.message);
      return { success: false, error: error.response?.data || error.message };
    }
  };
}

export function insertQualitativeStandard(type, NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, typeof NewLists[key] === 'object' ? JSON.stringify(NewLists[key]) : NewLists[key]);
        }
      });

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/qualitativeStandard/${type}`, data, config);

      if (response.status === 200) {
        dispatch(insertQualitative({ success: true, type, item: response.data }));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message || 'Something went wrong' };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}
