import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';

const initialState = {
  hwaseongTechList: [],
  hwaseongTechInfoList: [],
  error: null
};

const slice = createSlice({
  name: 'hwaseongTech',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getHwaseongTechListSuccess(state, action) {
      state.hwaseongTechList = action.payload;
    },
    getHwaseongTechInfoListSuccess(state, action) {
      state.hwaseongTechInfoList = action.payload;
    }
  }
});

export const { getHwaseongTechListSuccess, getHwaseongTechInfoListSuccess } = slice.actions;
export default slice.reducer;

export function getHwaseongTechList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/hwaseongTech/list' });
      dispatch(getHwaseongTechListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}

export function getHwaseongTechInfoList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/hwaseongTech/info_list' });
      dispatch(getHwaseongTechInfoListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}
