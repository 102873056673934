import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lists: [],
  error: null
};

const slice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    createContactUs(state, action) {
      state.lists.push(action.payload);
    }
  }
});

export const { createContactUs } = slice.actions;
export default slice.reducer;

export function getContactUsInsert(newData) {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      const fields = ['name', 'company', 'email', 'phone', 'message', 'agreement'];
      fields.forEach((field) => {
        if (newData[field] !== undefined) {
          formData.append(field, newData[field]);
        }
      });

      const token = window.localStorage.getItem('serviceToken');
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          'x-access-token': token
        }
      };

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/contactUs/contactUsUpload`, formData, config);

      if (response.status === 200) {
        dispatch(slice.actions.createContactUs(response.data));
        return { success: true, data: response.data };
      } else {
        dispatch(slice.actions.hasError(response.data.message || 'An error occurred'));
        return { success: false, message: response.data.message || 'An error occurred' };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message || 'An error occurred' };
    }
  };
}
