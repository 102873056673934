import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null
};

const slice = createSlice({
  name: 'qualitative',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    getSingleList(state, action) {
      state.list = action.payload;
    },
    createQualitative(state, action) {
      state.lists.items = [...state.lists.items, action.payload];
      state.lists.totalItems += 1;
    },
    updateQualitative(state, action) {
      const updatedQualitative = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedQualitative.id ? updatedQualitative : item));
    },
    deleteQualitative(state, action) {
      const qualitativeId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== qualitativeId);
      state.lists.totalItems -= 1;
    }
  }
});

export const { getLists, getSingleList, createQualitative, updateQualitative, deleteQualitative } = slice.actions;

export default slice.reducer;

export function getQualitativeList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/qualitative/list',
        params: { page, pageSize }
      };
      const response = await ApiService.get(objData);
      // console.log('response', response);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getQualitativeInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (key === 'qualitativeStandards' && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, NewLists[key]);
        }
      });
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/qualitative/qualitativeUpload`, data, config);
      if (response.status === 200) {
        dispatch(createQualitative(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getQualitative(id) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/api/qualitative/details', { id });
      dispatch(getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getQualitativeSingleList(qualitativeId) {
  return async (dispatch) => {
    try {
      const response = await axios.post('/api/qualitative/list/single', qualitativeId);
      dispatch(getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

// export function getQualitativeUpdate(NewLists) {
//   return async (dispatch) => {
//     try {
//       const data = new FormData();
//       Object.keys(NewLists).forEach((key) => {
//         if (key === 'files' && NewLists[key] && NewLists[key][0]) {
//           data.append('file', NewLists[key][0]);
//         } else if (key === 'qualitativeStandards' && Array.isArray(NewLists[key])) {
//           data.append(key, JSON.stringify(NewLists[key]));
//         } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
//           data.append(key, NewLists[key]);
//         }
//       });
//       const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/qualitative/qualitativeUpdate`, data, config);
//       console.log('response:', response);
//       if (response.status === 200) {
//         dispatch(updateQualitative(response.data));
//         return { success: true, data: response.data };
//       } else {
//         return { success: false, message: response.message };
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error.toString()));
//       return { success: false, error };
//     }
//   };
// }

export function getQualitativeUpdate(NewLists) {
  return async (dispatch) => {
    try {
      let response;
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (key === 'qualitativeStandards' && Array.isArray(NewLists[key])) {
          data.append(key, JSON.stringify(NewLists[key]));
        } else {
          data.append(key, NewLists[key]);
        }
      });
      response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/qualitative/qualitativeUpdate`, data, config);
      console.log('response:', response);
      if (response.status === 200) {
        dispatch(updateQualitative(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getQualitativeDelete(qualitativeId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/qualitative/delete',
        data: { id: qualitativeId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteQualitative(qualitativeId));
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
