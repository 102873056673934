import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null
};

const slice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    getGroupLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    createInventory(state, action) {
      state.lists.items = [...state.lists.items, action.payload];
      state.lists.totalItems += 1;
    },
    updateInventory(state, action) {
      const updatedInventory = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedInventory.id ? updatedInventory : item));
    },
    deleteInventory(state, action) {
      const inventoryId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== inventoryId);
      state.lists.totalItems -= 1;
    }
  }
});

export const { getLists, getGroupLists, createInventory, updateInventory, deleteInventory } = slice.actions;

export default slice.reducer;

export function getInventoryList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/inventory/list',
        params: {
          page,
          pageSize
        }
      };
      const response = await ApiService.get(objData);
      console.log('response:', response.data);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getInventoryGroupList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/inventory/group',
        params: {
          page,
          pageSize
        }
      };
      const response = await ApiService.get(objData);
      dispatch(
        getGroupLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getInventoryInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (key === 'productBomItems' && Array.isArray(NewLists[key])) {
          // productBomItems를 JSON 문자열로 변환하여 추가
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, NewLists[key]);
        }
      });
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/inventory/inventoryUpload`, data, config);
      if (response.status === 200) {
        dispatch(createInventory(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getInventoryUpdate(NewLists, id, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const data = new FormData();
        Object.keys(NewLists).forEach((key) => {
          if (key === 'files' && NewLists[key][0]) {
            data.append('file', NewLists[key][0]);
          } else {
            data.append(key, NewLists[key]);
          }
        });
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/inventory/inventoryUpdate`, data, config);
      } else {
        const objData = {
          path: '/inventory/update',
          data: NewLists
        };
        response = await ApiService.post(objData);
      }

      if (response.status === 200) {
        dispatch(updateInventory(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getInventoryDelete(inventoryId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/inventory/delete',
        data: { id: inventoryId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteInventory({ inventoryId }));
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
