// project imports
import ApiService from 'services/ApiService';
import { dispatch } from '../index';

// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  list: null,
  error: null
};

const slice = createSlice({
  name: 'power',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    // get Power Service Key
    getSingleList(state, action) {
      state.list = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const { getSingleList } = slice.actions;

// ----------------------------------------------------------------------

export function getServiceInfo() {
  return async () => {
    try {
      const objData = {
        path: '/power/serviceInfo'
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
