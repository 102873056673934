import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
// import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// mes
const AppMesProductList = Loadable(lazy(() => import('pages/mes/product/list')));
const AppMesProductCreate = Loadable(lazy(() => import('pages/mes/product/create')));
const AppMesProductEdit = Loadable(lazy(() => import('pages/mes/product/edit')));
const AppMesProductDetails = Loadable(lazy(() => import('pages/mes/product/details')));

const AppMesMaterialList = Loadable(lazy(() => import('pages/mes/material/list')));
const AppMesMaterialCreate = Loadable(lazy(() => import('pages/mes/material/create')));
const AppMesMaterialEdit = Loadable(lazy(() => import('pages/mes/material/edit')));

const AppMesMaterialHistoryList = Loadable(lazy(() => import('pages/mes/materialHistory/list')));
const AppMesMaterialHistoryCreate = Loadable(lazy(() => import('pages/mes/materialHistory/create')));
const AppMesMaterialHistoryEdit = Loadable(lazy(() => import('pages/mes/materialHistory/edit')));

const AppMesMaterialOutList = Loadable(lazy(() => import('pages/mes/materialOut/list')));
const AppMesMaterialOutCreate = Loadable(lazy(() => import('pages/mes/materialOut/create')));
const AppMesMaterialOutEdit = Loadable(lazy(() => import('pages/mes/materialOut/edit')));
const AppMesMaterialStandards = Loadable(lazy(() => import('pages/mes/material/materialStandards')));

const AppMesInvoiceList = Loadable(lazy(() => import('pages/mes/invoice/list')));
const AppMesInvoiceCreate = Loadable(lazy(() => import('pages/mes/invoice/create')));
const AppMesInvoiceEdit = Loadable(lazy(() => import('pages/mes/invoice/edit')));
const AppMesInvoiceDetails = Loadable(lazy(() => import('pages/mes/invoice/details')));

const AppMesInvoiceProductionList = Loadable(lazy(() => import('pages/mes/invoiceProductionList/list')));
const AppMesInvoiceProductionCreate = Loadable(lazy(() => import('pages/mes/invoiceProductionList/create')));
const AppMesInvoiceProductionEdit = Loadable(lazy(() => import('pages/mes/invoiceProductionList/edit')));

const AppMesProductionPlanList = Loadable(lazy(() => import('pages/mes/productionPlan/list')));
const AppMesProductionPlanEndList = Loadable(lazy(() => import('pages/mes/productionPlan/endList')));
const AppMesProductionPlanCreate = Loadable(lazy(() => import('pages/mes/productionPlan/create')));
const AppMesProductionPlanEdit = Loadable(lazy(() => import('pages/mes/productionPlan/edit')));
const AppMesProductionPlanOutput = Loadable(lazy(() => import('pages/mes/productionPlan/planOutput')));
const AppMesProductionPlanOutputEdit = Loadable(lazy(() => import('pages/mes/productionPlan/planOutputEdit')));
const AppMesProductionPlanEnd = Loadable(lazy(() => import('pages/mes/productionPlan/end')));
const AppMesProductionPlanDetails = Loadable(lazy(() => import('pages/mes/productionPlan/details')));

const AppMesInventoryList = Loadable(lazy(() => import('pages/mes/inventory/list')));
const AppMesInventoryCreate = Loadable(lazy(() => import('pages/mes/inventory/create')));
const AppMesInventoryEdit = Loadable(lazy(() => import('pages/mes/inventory/edit')));

const AppMesShipmentList = Loadable(lazy(() => import('pages/mes/shipment/list')));
const AppMesShipmentCreate = Loadable(lazy(() => import('pages/mes/shipment/create')));
const AppMesShipmentNew = Loadable(lazy(() => import('pages/mes/shipment/new')));
const AppMesShipmentEdit = Loadable(lazy(() => import('pages/mes/shipment/edit')));
const AppMesShipmentDetails = Loadable(lazy(() => import('pages/mes/shipment/details')));

const AppMesQualityList = Loadable(lazy(() => import('pages/mes/quality/list')));
const AppMesQualityEndList = Loadable(lazy(() => import('pages/mes/quality/endList')));
const AppMesQualityCreate = Loadable(lazy(() => import('pages/mes/quality/create')));
const AppMesQualityCreateNoPlan = Loadable(lazy(() => import('pages/mes/quality/createNoPlan')));
const AppMesQualityEdit = Loadable(lazy(() => import('pages/mes/quality/edit')));
const AppMesQualityDetails = Loadable(lazy(() => import('pages/mes/quality/details')));
const AppMesQualityLinkEdit = Loadable(lazy(() => import('pages/mes/quality/qualityLinkEdit')));
const AppMesQualityLinkDetails = Loadable(lazy(() => import('pages/mes/quality/qualityLinkDetails')));
const AppMesQualityStandardSetup = Loadable(lazy(() => import('pages/mes/quality/qualityStandardSetup')));

// ==============================|| MES ROUTING ||============================== //

const MesRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'mes',
          children: [
            // 제품
            {
              path: 'product',
              element: <AppMesProductList />
            },
            {
              path: 'product_create',
              element: <AppMesProductCreate />
            },
            {
              path: 'product_edit/:id',
              element: <AppMesProductEdit />
            },
            {
              path: 'product_details/:id',
              element: <AppMesProductDetails />
            },
            // 자재
            {
              path: 'material',
              element: <AppMesMaterialList />
            },
            {
              path: 'material_create',
              element: <AppMesMaterialCreate />
            },
            {
              path: 'material_edit/:id',
              element: <AppMesMaterialEdit />
            },
            // 자재입고이력
            {
              path: 'materialHistory',
              element: <AppMesMaterialHistoryList />
            },
            {
              path: 'materialHistory_create',
              element: <AppMesMaterialHistoryCreate />
            },
            {
              path: 'materialHistory_edit/:id',
              element: <AppMesMaterialHistoryEdit />
            },
            // 자재출고이력
            {
              path: 'materialOut',
              element: <AppMesMaterialOutList />
            },
            {
              path: 'materialOut_create',
              element: <AppMesMaterialOutCreate />
            },
            {
              path: 'materialOut_edit/:id',
              element: <AppMesMaterialOutEdit />
            },
            {
              path: 'materialStandards',
              element: <AppMesMaterialStandards />
            },
            // 주문
            {
              path: 'invoice',
              element: <AppMesInvoiceList />
            },
            {
              path: 'invoice_create',
              element: <AppMesInvoiceCreate />
            },
            {
              path: 'invoice_edit/:id',
              element: <AppMesInvoiceEdit />
            },
            {
              path: 'invoice_details/:id',
              element: <AppMesInvoiceDetails />
            },
            // 생산주문목록
            {
              path: 'invoiceProductionList',
              element: <AppMesInvoiceProductionList />
            },
            {
              path: 'invoiceProductionList_create',
              element: <AppMesInvoiceProductionCreate />
            },
            {
              path: 'invoiceProductionList_edit/:id',
              element: <AppMesInvoiceProductionEdit />
            },
            // 생산계획
            {
              path: 'productionPlan',
              element: <AppMesProductionPlanList />
            },
            {
              path: 'productionPlan_end_list',
              element: <AppMesProductionPlanEndList />
            },
            {
              path: 'productionPlan_create/:id',
              element: <AppMesProductionPlanCreate />
            },
            {
              path: 'productionPlan_edit/:id',
              element: <AppMesProductionPlanEdit />
            },
            {
              path: 'productionPlan_out/:id',
              element: <AppMesProductionPlanOutput />
            },
            {
              path: 'productionPlan_output_edit/:id',
              element: <AppMesProductionPlanOutputEdit />
            },
            {
              path: 'productionPlan_end/:id',
              element: <AppMesProductionPlanEnd />
            },
            {
              path: 'productionPlan_details/:id',
              element: <AppMesProductionPlanDetails />
            },
            // 재고
            {
              path: 'inventory',
              element: <AppMesInventoryList />
            },
            {
              path: 'inventory_create',
              element: <AppMesInventoryCreate />
            },
            {
              path: 'inventory_edit/:id',
              element: <AppMesInventoryEdit />
            },
            // 출하
            {
              path: 'shipment',
              element: <AppMesShipmentList />
            },
            {
              path: 'shipment_create/:id',
              element: <AppMesShipmentCreate />
            },
            {
              path: 'shipment_new',
              element: <AppMesShipmentNew />
            },
            {
              path: 'shipment_edit/:id',
              element: <AppMesShipmentEdit />
            },
            {
              path: 'shipment_details/:id',
              element: <AppMesShipmentDetails />
            },
            // 품질검사
            {
              path: 'quality',
              element: <AppMesQualityList />
            },
            {
              path: 'quality_end',
              element: <AppMesQualityEndList />
            },
            {
              path: 'quality_create/:id',
              element: <AppMesQualityCreate />
            },
            {
              path: 'quality_create_no_plan/:id',
              element: <AppMesQualityCreateNoPlan />
            },
            {
              path: 'quality_edit/:id',
              element: <AppMesQualityEdit />
            },
            {
              path: 'quality_details/:id',
              element: <AppMesQualityDetails />
            },
            {
              path: 'qualityLink_edit/:id',
              element: <AppMesQualityLinkEdit />
            },
            {
              path: 'qualityLink_details/:id',
              element: <AppMesQualityLinkDetails />
            },
            {
              path: 'qualityStandardSetup',
              element: <AppMesQualityStandardSetup />
            }
          ]
        }
      ]
    }
  ]
};

export default MesRoutes;
