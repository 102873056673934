import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiService from 'services/ApiService';

const initialState = {
  lists: [],
  error: null
};

const slice = createSlice({
  name: 'qna',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
    },
    createQna(state, action) {
      state.lists.push(action.payload);
    },
    updateQna(state, action) {
      const updatedQna = action.payload;
      const index = state.lists.findIndex((item) => item.id === updatedQna.id);
      if (index !== -1) {
        state.lists[index] = updatedQna;
      }
    },
    deleteQna(state, action) {
      state.lists = state.lists.filter((item) => item.id !== action.payload);
    }
  }
});

export const { getLists, createQna, updateQna, deleteQna } = slice.actions;
export default slice.reducer;

const config = {
  headers: {
    'content-type': 'multipart/form-data',
    'x-access-token': localStorage.getItem('serviceToken')
  }
};

function createFormData(data) {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === 'files' && data[key]) {
      data[key].forEach((file) => formData.append('files', file));
    } else if (key === 'addedFiles' && data[key]) {
      data[key].forEach((file) => formData.append('addedFiles', file));
    } else if (key === 'deletedFiles' && data[key]) {
      formData.append('deletedFiles', JSON.stringify(data[key]));
    } else if (data[key] !== undefined) {
      formData.append(key, data[key]);
    }
  });
  return formData;
}

export function getQnaList() {
  return async (dispatch) => {
    try {
      const response = await ApiService.get({ path: '/qna/list' });
      dispatch(getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
    }
  };
}

export function getQnaInsert(newQna) {
  return async (dispatch) => {
    try {
      const formData = createFormData(newQna);
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/qna/qnaUpload`, formData, config);

      if (response.status === 200) {
        dispatch(createQna(response.data));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || 'Failed to insert QnA');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}

export function getQnaUpdate(updatedQna, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const formData = createFormData(updatedQna);
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/qna/qnaUpdate`, formData, config);
      } else {
        response = await ApiService.post({
          path: '/qna/update',
          data: updatedQna
        });
      }

      if (response.status === 200) {
        dispatch(updateQna(response.data));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || 'Failed to update QnA');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}

export function getQnaDelete(qnaId) {
  return async (dispatch) => {
    try {
      const response = await ApiService.post({
        path: '/qna/delete',
        data: { id: qnaId }
      });
      if (response.status === 200) {
        dispatch(deleteQna(qnaId));
        return { success: true };
      } else {
        throw new Error(response.data.message || 'Failed to delete QnA');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}
