import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
// import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// edge
const AppEdgeDeviceList = Loadable(lazy(() => import('pages/edge/edgeDevice/list')));
const AppEdgeDeviceCreate = Loadable(lazy(() => import('pages/edge/edgeDevice/create')));
const AppEdgeDeviceEdit = Loadable(lazy(() => import('pages/edge/edgeDevice/edit')));

// lms Routes

const LmsRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'edge',
          children: [
            {
              path: 'edgeDevice',
              children: [
                {
                  path: 'list',
                  element: <AppEdgeDeviceList />
                },
                {
                  path: 'create',
                  element: <AppEdgeDeviceCreate />
                },
                {
                  path: 'edit/:id',
                  element: <AppEdgeDeviceEdit />
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default LmsRoutes;
