import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null,
  alertPopup: false
};

const slice = createSlice({
  name: 'mold',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    createMold(state, action) {
      state.lists.items = [...state.lists.items, action.payload];
      state.lists.totalItems += 1;
    },
    createMoldExcel(state, action) {
      state.lists.items = [...state.lists.items, ...action.payload];
      state.lists.totalItems += action.payload.length;
    },
    updateMold(state, action) {
      const updatedMold = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedMold.id ? updatedMold : item));
    },
    deleteMold(state, action) {
      const moldId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== moldId);
      state.lists.totalItems -= 1;
    },
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

export const { getLists, createMold, createMoldExcel, updateMold, deleteMold, alertPopupToggle } = slice.actions;

export default slice.reducer;

export function getMoldList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/mold/list',
        params: {
          page,
          pageSize
        }
      };

      // console.log('objData', objData);
      const response = await ApiService.get(objData);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getMoldInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'products') {
          data.append(key, JSON.stringify(NewLists[key]));
        } else if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, typeof NewLists[key] === 'object' ? JSON.stringify(NewLists[key]) : NewLists[key]);
        }
      });
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/mold/moldUpload`, data, config);
      if (response.status === 200) {
        dispatch(createMold(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getMoldUpdate(NewLists, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const data = new FormData();
        Object.keys(NewLists).forEach((key) => {
          if (key === 'products') {
            data.append(key, JSON.stringify(NewLists[key]));
          } else if (key === 'files' && NewLists[key] && NewLists[key][0]) {
            data.append('file', NewLists[key][0]);
          } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
            data.append(key, typeof NewLists[key] === 'object' ? JSON.stringify(NewLists[key]) : NewLists[key]);
          }
        });
        response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/mold/moldUpdate`, data, config);
      } else {
        const objData = {
          path: '/mold/update',
          data: NewLists
        };
        response = await ApiService.post(objData);
      }

      if (response.status === 200) {
        dispatch(updateMold(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function putMoldExcelUpload(combinedData) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      data.append('excels', JSON.stringify(combinedData.excels));
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/mold/moldExcelUpload`, data, config);
      if (response.status === 200) {
        dispatch(createMold(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getMoldDelete(moldId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/mold/delete',
        data: { id: moldId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteMold(moldId));
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
