import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  lists: [],
  error: null
};

const slice = createSlice({
  name: 'factoryMachinePlcAddressTexts',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    createFactoryMachinePlcAddressTexts(state, action) {
      state.lists.push(action.payload);
    }
  }
});

export const { createFactoryMachinePlcAddressTexts } = slice.actions;
export default slice.reducer;

const config = {
  headers: {
    'content-type': 'multipart/form-data',
    'x-access-token': localStorage.getItem('serviceToken')
  }
};

export function getFactoryMachinePlcAddressTextsUpsert(newTexts) {
  return async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append('texts', JSON.stringify(newTexts.texts));

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/factoryMachinePlcAddressTexts/Upsert`, formData, config);

      if (response.status === 200) {
        dispatch(createFactoryMachinePlcAddressTexts(response.data));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message || 'Failed to upsert texts');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.message || 'An error occurred'));
      return { success: false, error: error.message };
    }
  };
}
